import { Stack, type ButtonOwnProps } from '@mui/material';
import type { PropsWithChildren, ReactNode } from 'react';

import ButtonWithIcon from '../buttons/buttonWithIcon';

interface ButtonProps {
  text: string;
  onClick: () => Promise<void> | void;
  disabled?: boolean;
  variant?: ButtonOwnProps['variant'];
  'data-testid': string;
  icon?: string | ReactNode;
}

export interface PanelFooterProps {
  primaryButton: ButtonProps;
  secondaryButton?: ButtonProps;
}

export const PanelFooter = (props: PropsWithChildren<PanelFooterProps>) => {
  const { primaryButton, secondaryButton, children } = props;

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      paddingX='40px'
      paddingY='16px'
      className='rounded-b-[16px] shadow-[0px_10px_30px_0px_rgba(0,0,0,0.20)]'
    >
      <div>{children}</div>
      <Stack direction='row' gap='12px'>
        {secondaryButton && (
          <ButtonWithIcon
            {...secondaryButton}
            variant={secondaryButton.variant || 'text'}
          />
        )}
        <ButtonWithIcon
          {...primaryButton}
          variant={primaryButton.variant || 'contained'}
        />
      </Stack>
    </Stack>
  );
};
