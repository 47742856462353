import { Button, Stack, Typography, type ButtonProps } from '@mui/material';
import classNames from 'classnames';
import { forwardRef, type ReactNode } from 'react';
interface ButtonWithIconProps extends ButtonProps {
  icon?: string | ReactNode;
  iconPosition?: 'left' | 'right';
  text: string;
}

const ButtonWithIcon = forwardRef<HTMLButtonElement, ButtonWithIconProps>(
  (props, ref) => {
    const { icon, text, iconPosition = 'left', ...rest } = props;

    return (
      <Button ref={ref} size='small' className='px-0 h-[32px]' {...rest}>
        <Stack
          height='24px'
          direction={iconPosition === 'left' ? 'row' : 'row-reverse'}
          gap='8px'
          alignItems='center'
          className={classNames('px-[12px]', {
            'pr-[8px]': icon && iconPosition === 'right',
            'pl-[8px]': icon && iconPosition === 'left',
          })}
        >
          {icon &&
            (typeof icon === 'string' ? (
              <i className={`${icon} text-xl align-middle m-[2px]`} />
            ) : (
              icon
            ))}
          <Typography className='font-semibold' color='currentcolor'>
            {text}
          </Typography>
        </Stack>
      </Button>
    );
  }
);

export default ButtonWithIcon;
